import { ref, nextTick } from "vue";
import { mutation, query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_MUTATION, GQL_QUERIES } from "../../utils/APIs";

export const USER_FILES = ref([]);
export const ALL_FILES = ref({});
export const STREAMS = ref([]);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());
export const EMAIL_RESPONSE = ref({});
export const MARKET_SERVICES2 = ref([]);
export const SERIES_100 = ["101", "102", "103"];
export const SERIES_200 = ["201", "202", "203"];
export const SERIES_MONTHLY = ["201", "MARKET_SUPPORT"];
export const SERIES_COST = ["DATA", "MARKET_SUPPORT"];
export const SERIES_CRITICAL_MATERIALS = ["CRITICAL_MATERIALS", "MARKET_SUPPORT"];
export const SERIES_OTHERS = ["DATA", "PROXIMA", "MARKET_SUPPORT"];
export const COST_SERVICES = ref([]);
export const UNSUBSCRIBED_COST_SERVICES = ref([]);
export const MONTHLY_SERVICES = ref([]);
export const UNSUBSCRIBED_MONTHLY_SERVICES = ref([]);
export const critMatFiles = ref([]);
export const criticalMaterials = ref([]);
export const criticalMaterialsData = ref([]);
export const CRITICAL_MATERIALS_NAME = 'CRITICAL_MATERIALS'
export const MARKET_SERVICE_FILE = 'marketServices';
export const COST_SERVICE_FILE = 'costServices';
export const DATA_SERIES_TYPE = 'DATA';
export const MARKET_SVC_FOLDERS = ref([]);
export const sortedFolders = ref([]);
export const COST_FOLDERS = ref([]);
export const costSortedFolders = ref([]);
export const progressPercentage = ref(0)

export const getUserFiles = async () => {
  if (inProgress.value == true) {
    return;
  }
  inProgress.value = true;
  const gqlQuery = GQL_QUERIES.GET_USER_FILES;
  const interval = setInterval(() => {
    if (progressPercentage.value < 95) {
      progressPercentage.value += 2; // Smoothly increment progress
    } else {
      clearInterval(interval);
    }
  }, 50);
  let client = new HttpClient(gqlQuery.baseUrl);
  await client.get(`${gqlQuery.controller}/${gqlQuery.operation}`, {
      onUploadProgress: () => {
        progressPercentage.value = Math.min(progressPercentage.value + 20, 80);
      },
      onDownloadProgress: () => {
        progressPercentage.value = 98;
      }
    })
    .then(response => {
      clearInterval(interval); 
      MARKET_SERVICES2.value = response || [];
      nextTick(() => {
        progressPercentage.value = 100; 
      });
      let marketServiceTypeName = "marketservice";

      COST_SERVICES.value = MARKET_SERVICES2.value?.filter(r => r.type.toLowerCase() != marketServiceTypeName && r.isSubscribed == true);
      UNSUBSCRIBED_COST_SERVICES.value = MARKET_SERVICES2.value?.filter(r => r.type.toLowerCase() != marketServiceTypeName && r.isSubscribed == false);
      MONTHLY_SERVICES.value = MARKET_SERVICES2.value?.filter(r => r.type.toLowerCase() == marketServiceTypeName && r.isSubscribed == true);
      UNSUBSCRIBED_MONTHLY_SERVICES.value = MARKET_SERVICES2.value?.filter(r => r.type.toLowerCase() == marketServiceTypeName && r.isSubscribed == false);
      criticalMaterials.value = MARKET_SERVICES2.value?.filter(r => r.element === CRITICAL_MATERIALS_NAME);
      setTimeout(() => {
        inProgress.value = false;
        if (window.$loading) {
          window.$loading.finish();
        }
        progressPercentage.value = 0;
      }, 500);
    })
    .catch(error => {
      clearInterval(interval);
      if (window.$loading) {
        window.$loading.error();
      }
      inProgress.value = false;
      progressPercentage.value = 0; 
    });
};

export const getAllFiles = async () => {
  const gqlQuery = GQL_QUERIES.GET_ALL_FILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      fields: [
        "displayName",
        "elementBundles",
        "elements",
        "fileSeries",
        "fileType",
        "filename",
        "fileurl",
        "id",
        "isPublic",
        "key",
        "publishDateStart"
      ]
    }
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;
  ALL_FILES.value = response.data?.[gqlQuery.operation] || {};
}

export const getStreams = async () => {
  const gqlQuery = GQL_QUERIES.GET_STREAMS;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {},
      fields: ["displayName", "description", "uploadedDate", "fileUrl"],
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;

  STREAMS.value = response.data?.[gqlQuery.operation] || {};
};

export const sendFileToEmail = async (id) => {
  const gqlQuery = GQL_MUTATION.EMAIL_FILE;
  inProgress.value = true;

  let client = new HttpClient(`${gqlQuery.baseUrl}`);
  let url = `${gqlQuery.controller}/${gqlQuery.operation}/${id}`;
  client.post(url)
    .then(() => {
      window.$message.success("The file has been sent to your registered email address.");
      inProgress.value = false;
    });
};

export const downloadFile = async (id) => {
  const gqlQuery = GQL_MUTATION.DOWNLOAD_FILE;
  // inProgress.value = true;

  let client = new HttpClient(`${gqlQuery.baseUrl}`);
  let url = `${gqlQuery.controller}/${gqlQuery.operation}/${id}`;
  client.post(url)
    .then((response) => {
      downloadBase64File(response.file, response.fileName);
      // inProgress.value = false;
    });
  window.$message.success("Your file is now being prepared for download - do not close or refresh your browser.");
};

function downloadBase64File(base64String, fileName) {
  // Decode the Base64 string
  const byteCharacters = atob(base64String);

  // Convert the byte characters to a Uint8Array
  const byteNumbers = new Uint8Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([byteNumbers], { type: 'application/octet-stream' }); // Change the MIME type as needed

  // Create an Object URL for the Blob
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName; // Set the desired file name

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Clean up: remove the link and revoke the object URL
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
}

export const changeColor = (hex, alpha) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

