import { ref, nextTick } from "vue";
import { query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";

export const PUBLIC_ARTICLES = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());
export const progressPercentage = ref(0);

export const fields = [
  "id",
  "name",
  "publishDate",
  "authorName",
  "category",
  "countries",
  "companies",
  "elements",
  "products",
  "description",
  "feed",
  "opinion",
  "bannerImage",
  "externalUrl",
  "type",
  "authorAlias"
];

export const getPublicArticles = async () => {
  const gqlQuery = GQL_QUERIES.GET_PUBLIC_ARTICLES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  progressPercentage.value = 0;
  const interval = setInterval(() => {
      if (progressPercentage.value < 95) {
        progressPercentage.value += 2; // Smoothly increment progress
      } else {
        clearInterval(interval);
      }
  }, 50);
  
  let client = new HttpClient(gqlQuery.baseUrl);
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}`, {
    onUploadProgress: () => {
      progressPercentage.value = Math.min(progressPercentage.value + 20, 80);
    },
    onDownloadProgress: () => {
      progressPercentage.value = 98;
    }
  })
  .then(response => {
    PUBLIC_ARTICLES.value = response;
    nextTick(() => {
      progressPercentage.value = 100; 
    });
    setTimeout(() => {
      inProgress.value = false;
      if (window.$loading) {
        window.$loading.finish();
      }
      progressPercentage.value = 0;
    }, 500);
  })
  .catch(error => {
    inProgress.value = false;
    clearInterval(interval);
  });
};

export const changeColor = (hex, alpha) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
