<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="card shadow-lg w-xxl-100">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-lg bg-gradient-dark border-radius-md position-relative">
                <i class="ni ni-folder-17 text-white text-2xl"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-0">{{ $t("Files.costServiceTitle") }}</h5>
                <p class="mb-0 font-weight-bold text-xs">
                  {{ $t("Files.secondaryTitle") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div v-if="inProgress" class="col-12 mt-1 d-flex justify-content-center">
        <div class="loading-overlay">
          <NProgress
            style="margin: 8px 8px"
            type="circle"
            :percentage="progressPercentage"
            :color="'#192e40'"
            :rail-color="changeColor('#192e40', { alpha: 0.2 })"
            :indicator-text-color="'#192e40'"
          />
        </div>
      </div>
      <div v-else class="row g-2">
        <div class="col-md-6 col-xs-12" v-for="nth in COST_SERVICES || []" :key="nth">
          <div class="custom-card card shadow-lg w-xxl-100">
            <div class="card-body p-3">
              <div class="container p-0">
                <div class="row g-0">
                  <div class="col-md-3 col-xs-12 col-xxl-3 position-relative p-0">
                    <img :src="nth.thumbnail" class="img-fluid h-100 rounded cover-img" alt="..." />
                  </div>
                  <div class="col-md-9 col-xs-12 col-xxl-9 position-relative p-0">
                    <div class="col-12">
                      <h5 class="card-title p-0">
                        <div class="d-flex d-flex-row">
                          <img :src="nth.iconUrl" style="height: 30px; width: 30px;"
                            class="img-fluid h-100 rounded cover-img" alt="..." />
                          <span style="margin-left: 5px" class="p-0 cursor-pointer font-weight-bold text-lg">
                            <h4>
                              {{ nth.subscriptionName }}
                            </h4>
                          </span>
                        </div>
                      </h5>
                    </div>

                    <div>
                      <div class="col-12 position-relative">
                        <span class="custom-btn2 btn badge-sm text-white me-1 my-1 content-btn text-start p-1" :class="(!folder?.hasFiles && !folder?.redirectUrl)
                          ? 'proxima-disabled-button'
                          : 'proxima-blue-button'
                          " v-for="folder in nth.folders" :key="folder" @click="viewFiles(folder, true)">
                          <i :class="!folder?.redirectUrl
                            ? 'fa fa-download text-xs download-icon'
                            : 'fa fa-external-link text-xs download-icon'" data-v-1ef3e808=""></i>
                          {{ shortenFileName(folder.folderName) }}

                          <span>
                            <PopOver :text="folder.folderName" :isMonthly="true" :isCriticalMaterials="false"
                              :data="null" />
                          </span>
                        </span>
                        <span class="custom-btn2 btn badge-sm text-white me-1 my-1 content-btn text-start p-1"
                          :class="'proxima-blue-button'" :key="file"
                          @click="handleEvent(nth)">
                          <img :src="'images/multiple-users-silhouette.png'" alt="pdf icon"
                            class="image-fluid file-icon" style="width: 11px; height: 13px;"/> Market Support
                          <span>
                            <PopOver :text="'Market Services'" :isMonthly="true" :isCriticalMaterials="false"
                              :data="null" />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-4 container-fluid">
      <div class="card shadow-lg w-xxl-100">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-lg bg-gradient-dark border-radius-md position-relative">
                <i class="ni ni-folder-17 text-white text-2xl"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-0">Unsubscribed Cost Services</h5>
                <p class="mb-0 font-weight-bold text-xs">
                  Contact the team to learn more about our other service offerings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div v-if="inProgress" class="col-12 mt-1 d-flex justify-content-center">
        <NProgress
          style="margin: 8px 8px 12px 0;"
          type="circle"
          :percentage="progressPercentage"
          :color="'#192e40'"
          :rail-color="changeColor('#192e40', { alpha: 0.2 })"
          :indicator-text-color="'#192e40'"
        />
      </div>
      <div v-else class="row g-2">
        <div class="col-md-6 col-xs-12" v-for="nth in UNSUBSCRIBED_COST_SERVICES || []" :key="nth">
          <div class="custom-card card shadow-lg w-xxl-100">
            <div class="card-body p-3">
              <div class="container p-0">
                <div class="row g-0">
                  <div class="col-md-3 col-xs-12 col-xxl-3 position-relative p-0">
                    <img :src="nth.thumbnail" class="img-fluid h-100 rounded cover-img" alt="..." />
                  </div>
                  <div class="col-md-9 col-xs-12 col-xxl-9 position-relative p-0">
                    <div class="col-12">
                      <h5 class="card-title p-0">
                        <div class="d-flex d-flex-row">
                          <img :src="nth.iconUrl" style="height: 30px; width: 30px;"
                            class="img-fluid h-100 rounded cover-img" alt="..." />
                          <span style="margin-left: 5px" class="p-0 cursor-pointer font-weight-bold text-lg">
                            <h4>
                              {{ nth.subscriptionName }}
                            </h4>
                          </span>
                        </div>
                      </h5>
                    </div>
                    <div>
                      <div class="col-12 position-relative">
                        <span class="custom-btn2 btn badge-sm text-white me-1 my-1 content-btn text-start p-1" :class="!folder?.hasFiles
                          ? 'proxima-disabled-button'
                          : 'proxima-blue-button'
                          " v-for="folder in nth.folders" :key="folder" @click="viewFiles(folder, false)">
                          <i class="fa fa-download text-xs download-icon" data-v-1ef3e808=""></i>
                          {{ shortenFileName(folder.folderName) }}

                          <span>
                            <PopOver :text="folder.folderName" :isMonthly="true" :isCriticalMaterials="false"
                              :data="null" />
                          </span>
                        </span>
                        <span class="custom-btn2 btn badge-sm text-white me-1 my-1 content-btn text-start p-1"
                          :class="'proxima-blue-button'" :key="file"
                          @click="handleEvent(nth)">
                          <img :src="'images/multiple-users-silhouette.png'" alt="pdf icon"
                            class="image-fluid file-icon" style="width: 11px; height: 13px;"/> Market Support
                          <span>
                            <PopOver :text="'Market Services'" :isMonthly="true" :isCriticalMaterials="false"
                              :data="null" />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NDrawer v-model:show="showDrawer" @update:show="clearProp()" :width="502" placement="right">
      <NDrawerContent>
        <div class="row">
          <div class="col-12">
            <div class="card mb-4">
              <div class="card-header pb-0">
                <h6 class="text-capitalize" v-if="showCritMatList">
                  {{ $t(`Files.seriesNames.CRITICAL_MATERIALS`) }}
                </h6>
                <h6 class="text-capitalize" v-if="showMarketSupportForm">
                  {{ $t(`Files.seriesNames.MARKET_SUPPORT`) }}
                </h6>
                <h6 class="text-capitalize" v-if="showFiles">
                  <span>
                    {{ folderName }}
                  </span>
                </h6>
              </div>
              <div class="card-body px-0 pt-0" v-if="showCritMatList">
                <NDataTable :columns="columns" :data="criticalMaterialsData" />
              </div>
              <div class="card-body pt-1" v-if="showMarketSupportForm">
                <MarketSupportForm :elementProp="elementArrayProp" :userEmail="userEmail" :product="product"
                  @customEvent="handleCustomEvent">
                </MarketSupportForm>
              </div>
              <div class="card-body px-0 pt-0" v-if="showFiles">
                <NDataTable :columns="columns" :data="data" />
              </div>
            </div>
          </div>
        </div>
      </NDrawerContent>
    </NDrawer>
  </main>
</template>

<script setup>
import { sortBy, forEach } from "lodash";
import jwtDecode from "jwt-decode";
import { onMounted, ref, computed, h, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  NDrawer,
  NDrawerContent,
  NDataTable,
  NProgress,
} from "naive-ui";
import { ROUTES } from "../../routes/names";
import {
  USER_FILES,
  getUserFiles,
  MARKET_SERVICES2,
  COST_SERVICES,
  MONTHLY_SERVICES,
  CRITICAL_MATERIALS_NAME,
  inProgress,
  progressPercentage,
  changeColor,
  DATA_SERIES_TYPE,
  UNSUBSCRIBED_COST_SERVICES
} from "../../composables/files/list";
import TableCell from "../../components/FilesTable/TableCell";
import TableCellDownload from "../../components/FilesTable/TableCellDownload";
import TableHeader from "../../components/FilesTable/TableHeader";
import { useI18n } from "vue-i18n";
import SeriesNames from "../../utils/series.json";
import MarketSupportForm from "../../components/Drawer/MarketSupport";

import { MARKET_SERVICES } from "../../composables/generics";
import DefaultServicesBtns from "../../components/FilesButtons/DefaultServicesBtns";
import MonthlyServicesBtns from "../../components/FilesButtons/MonthlyServicesBtns";
import CostServicesBtns from "../../components/FilesButtons/CostServicesBtns";
import CriticalMaterialsBtns from "../../components/FilesButtons/CriticalMaterialsBtns";

const router = useRouter();
const route = useRoute();

const { t } = useI18n();
const showDrawer = ref(false);
const showCritMatList = ref(false);
const showMarketSupportForm = ref(false);
const showFiles = ref(false);
const selectedSeries = ref(null);
let selectedElement = ref(null);
let product = ref(null)
let folderName = ref(null);
let elementArrayProp = ref([]);
const FOLDERS = ref([]);
const criticalMaterialsData = ref([]);
const sortedFolders = ref([]);
const isMonthlyService = ref(null);

  const viewFiles = (folder, isSubscribed) => {
    if (folder.redirectUrl && isSubscribed) {
      window.location.href = folder.redirectUrl;
      return;
    }
    if (!folder?.hasFiles) {
      return;
    }

    filesInSeries.value = (folder.files || []).map((nth) => {
      return {
        title: nth.fileName,
        type: nth.fileType,
        url: nth.fileUrl,
        id: nth.fileUuid,
      };
    });

    showDrawer.value = !showDrawer.value;
    showFiles.value = true;
    showMarketSupportForm.value = false;
    return;
  };

  const shortenFileName = (fileName) => {
    let length = 20;
    return fileName.length > length ? fileName.substring(0, length) + '...' : fileName;
  }

  const getFileTypeImage = (fileType) => {
    switch (fileType.toLowerCase()) {
      case "pdf":
        return "images/pdf_icon.png";
      case "xls":
      case "xlsx":
        return "images/xls_icon.png";
    }
  }

const userEmail = computed(() => {
  const jwtToken = localStorage.getItem("access");

  if (jwtToken) {
    var decoded = jwtDecode(jwtToken);
    return decoded.email;
  }
});

const clearProp = (newValue) => {
  if (!newValue) {
    if (route.query?.element) {
      router.push({ name: ROUTES.COST_ANALYSIS.name });
    }
    elementArrayProp.value = [];
  }
};

// TODO: need to refactor this and make a single component for files list
const filesInSeries = ref([]);
const columns = computed(() => {
  return [
    {
      key: "name",
      title(column) {
        return h(TableHeader, {
          text: t("Files.fileHeader"),
        });
      },
      render: (row) => {
        return h(TableCell, {
          text: row.title,
          url: row.url,
          type: row.type,
        });
      },
    },
    {
      key: "action",
      title: null,
      render: (row) => {
        return h(TableCellDownload, {
          selectedElement: selectedElement,
          text: row.title,
          url: row.url,
          id: row.id,
        });
      },
    },
  ];
});

const data = computed(() => {
  return filesInSeries.value?.map((nth) => {
    return {
      title: nth.title,
      type: nth.type,
      url: nth.url,
      id: nth.id,
    };
  });
});

const handleEvent = (service) => {
  product.value = service.subscriptionName
  showDrawer.value = true; 
  showMarketSupportForm.value = true; 
  showFiles.value = false
}
// getUserFiles()
onMounted(async () => {
  if (!USER_FILES.value?.files) await getUserFiles();

  FOLDERS.value = MARKET_SERVICES2.value?.filter(r => r.isPublic === true && r.serviceType === COST_SERVICE_FILE)
    .map(service => {
      const fileMatch = USER_FILES.value?.files.find(file => file.element === service.element);

      return {
        ...service,
        files: fileMatch ? fileMatch.files : [],
      };
    });

  const foldersWithFiles = FOLDERS.value?.filter(folder => folder.files.length > 0
    && folder.element.toUpperCase().replaceAll(' ', '_') !== CRITICAL_MATERIALS_NAME)
    .sort((a, b) => a.name.localeCompare(b.name));

  const otherFolders = FOLDERS.value?.filter(folder => folder.files.length === 0
    && folder.element.toUpperCase().replaceAll(' ', '_') !== CRITICAL_MATERIALS_NAME)
    .sort((a, b) => a.name.localeCompare(b.name));

  sortedFolders.value = [...foldersWithFiles, ...otherFolders].filter(Boolean)

  if (route.query.element) {
    let result = null;

    result = sortedFolders.value.filter(r => r.element === route.query?.element).map(r => {
      let fileData = {
        series: DATA_SERIES_TYPE,
        name: r.name,
        element: r.element,
        files: r.files
      }
      return fileData;
    })
    await viewFiles(result[0]);
  }
  if(USER_FILES.value?.files) window.$loading.finish();
});
onBeforeMount(()=>{
  window.$loading.start();
})
</script>

<style lang="scss" scope>
.loading-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.n-input-group {
  display: inline-flex;
  width: 100%;
  flex-wrap: nowrap;
  vertical-align: bottom;
}

.proxima-blue-banner-image {
  background-image: url("/images/Critical_Materials_Banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: center;
}

.proxima-blue-banner-image:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, #192e40, #000);
  opacity: 0.5;
}

.pdf-btn {
  background-color: #f1ae31;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 0.75rem;
  height: 48px;
  width: 48px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #192e40;
  }
}

.xls-btn {
  background-color: green;

  .cover-img {
    width: 100%;
    height: 50%;
    /* Adjust image size */
  }

  .custom-card {
    margin-bottom: 20px;
  }

  .custom-btn {
    width: 100%;
  }

  .custom-btn-txt {
    font-size: 12px;
    /* Adjust font size */
  }

  .custom-btn2 {
    width: 100%;
  }

  .custom-btn-txt2 {
    font-size: 12px;
    /* Adjust font size */
  }

  .file-icon {
    width: 20px;
    /* Adjust icon size */
  }
}

// Medium devices (tablets, 768px and up)
@media (width >=768px) {
  .box {
    background-color: yellow;
  }

  .custom-btn {
    width: 31%;
    font-size: 5px;
  }

  .custom-btn-txt {
    font-size: 10px;
  }

  .custom-btn2 {
    width: 31%;
    font-size: 3px;
  }

  .custom-btn-txt2 {
    font-size: 10px;
  }

  .file-icon {
    width: 10px;
  }
}

// Large devices (desktops, 992px and up)
@media (width >=992px) {
  .box {
    background-color: blue;
  }

  .custom-btn {
    width: 32%;
    font-size: 5px;
  }

  .custom-btn2 {
    width: 32%;
    font-size: 3px;
  }

  .file-icon {
    width: 15px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (width >=1200px) {
  .box {
    background-color: pink;
  }

  .custom-btn {
    width: 32%;
    font-size: 5px;
  }

  .custom-btn2 {
    width: 32%;
    font-size: 3px;
  }

  .file-icon {
    width: 20px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (width >=1400px) {
  .box {
    background-color: black;
  }

  .cover-img {
    height: 100px;
  }

  .custom-btn {
    width: 32%;
  }

  .custom-btn-txt {
    font-size: 15px;
  }

  .custom-btn2 {
    width: 32%;
  }
}
</style>
